/*############### IMPORT CSS ###############*/
import "../css/main.css";

/*############### LOADER ###############*/
onload = () => {
    const load = document.getElementById("load");
    let url = window.location.href;
                
                // Get the part of the URL after the '?'
                let queryString = url.split('?')[1];
                
                if (queryString) {
                    // Split the query string into individual parameters
                    let params = queryString.split('&');
                    
                    // Create an object to hold the query parameters
                    let queryParams = {};
                    
                    // Iterate over each parameter and split it into key and value
                    params.forEach(param => {
                        let [key, value] = param.split('=');
                        // Decode the key and value to handle URL encoding
                        key = decodeURIComponent(key);
                        value = decodeURIComponent(value);
                        queryParams[key] = value;
                    });

                    // Set the values of the hidden inputs in the form
                    for (let key in queryParams) {
                        if (queryParams.hasOwnProperty(key)) {
                            let input = document.getElementById(key);
                            if (input) {
                                input.value = queryParams[key];
                            }
                        }
                    }
                }

    setTimeout(() => {
        load.style.display = "none";
    }, 2000)
}

/*############### SCROLL HEADER ###############*/
function scrollHeader() {
    const header = document.getElementById('header')
    // When the scroll is greater than 50 viewport height, add the scroll-header class to the header tag
    if (this.scrollY >= 50) header.classList.add('scroll-header'); else header.classList.remove('scroll-header')
}
window.addEventListener('scroll', scrollHeader)

/*############### SCROLL ACTIVE LINK ###############*/


/*############### SHOW SCROLL UP ###############*/
function scrollUp() {
    const scrollUp = document.getElementById('scroll-up');
    // When the scroll is higher than 460 viewport height, add the show-scroll class to the a tag with the scroll-top class
    if (this.scrollY >= 460) scrollUp.classList.add('show-scroll'); else scrollUp.classList.remove('show-scroll')
}
window.addEventListener('scroll', scrollUp)

/*############### FRAUDES SECTION ###############*/
var fraudesImages = document.querySelectorAll(".fraudes__content-image");

const accordionItems = document.querySelectorAll(".fraudes__item");
accordionItems.forEach((item, index) => {
    const accordionHeader = item.querySelector(".fraudes__header");

    accordionHeader.addEventListener("click", () => {
        const openItem = document.querySelector(".accordion-open")
        toggleItem(item);

        if (openItem && openItem !== item) {
            toggleItem(openItem);
        }

        fraudesImages.forEach(content => { content.classList.remove("fraudes-active-content") });
        fraudesImages[index].classList.add("fraudes-active-content");
    })
})

const toggleItem = (item) => {
    const accordionContent = item.querySelector(".fraudes__item-content");

    if (item.classList.contains("accordion-open")) {
        accordionContent.removeAttribute("style");
        item.classList.remove("accordion-open");
    } else {
        accordionContent.style.height = accordionContent.scrollHeight + "px";
        item.classList.add("accordion-open");
    }
}

// import LocomotiveScroll from 'locomotive-scroll';

// const scroll = new LocomotiveScroll({
//     el: document.querySelector('.main'),
//     smooth: true
// });


/*############### MENU LINK HOVER EFFECT ###############*/
let elements = document.querySelectorAll(".nav__link");

elements.forEach((element) => {
    let innerText = element.innerText;
    element.innerHTML = "";

    let textContainer = document.createElement("div");
    textContainer.classList.add("block");

    for (let letter of innerText) {
        let span = document.createElement("span");
        span.innerText = letter.trim() === "" ? "\xa0" : letter;
        span.classList.add("letter");

        textContainer.appendChild(span);
    }

    element.appendChild(textContainer);
    element.appendChild(textContainer.cloneNode(true));
});

elements.forEach((element) => {
    element.addEventListener("mouseover", () => {
        element.classList.remove("play");
    });
});

/*############### MENU ANIMATION ###############*/
const navToggle = document.getElementById('nav-toggle'),
    navClose = document.getElementById('nav-close');

var tl = gsap.timeline({ paused: true });

tl.to(".nav__menu", {
    right: 0,
    duration: 0.1,
})

tl.from(".nav__item", {
    x: 80,
    duration: 0.2,
    stagger: 0.1,
    opacity: 0.3,
})

tl.from(".nav__close", {
    opacity: 0,
})

tl.pause();

navToggle.addEventListener("click", () => {
    tl.play();
})

navClose.addEventListener("click", () => {
    tl.reverse();
})

const navLink = document.querySelectorAll('.nav__link')

function linkAction() {
    tl.reverse();
}
navLink.forEach(n => n.addEventListener('click', linkAction))

/*############### CALENDAR ###############*/
const currentDate = document.querySelector(".calendar__month"),
    daysTag = document.querySelector(".days"),
    prevNextButton = document.querySelectorAll(".calendar__button")

// getting new date, current year and month
let date = new Date(),
    currYear = date.getFullYear(),
    currMonth = date.getMonth();

const months = [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
    "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
]

const renderCalendar = () => {
    // getting last date of the month
    let firstDayOfMonth = new Date(currYear, currMonth, 1).getDay(),
        lastDateOfMonth = new Date(currYear, currMonth + 1, 0).getDate(),
        lastDayOfMonth = new Date(currYear, currMonth, lastDateOfMonth).getDay(),
        lastDateOfLastMonth = new Date(currYear, currMonth, 0).getDate();
    // console.log(lastDateOfMonth);
    let liTag = "";

    for (let i = firstDayOfMonth; i > 0; i--) {
        liTag += `<li class="inactive">${lastDateOfLastMonth - i + 1}</li>`;
    }

    for (let i = 1; i <= lastDateOfMonth; i++) {
        let isToday = i === date.getDate() && currMonth === new Date().getMonth()
            && currYear === new Date().getFullYear() ? "active" : "";

        liTag += `<li class="${isToday}">${i}</li>`;
    }

    for (let i = lastDayOfMonth; i < 6; i++) {
        liTag += `<li class="inactive">${i - lastDayOfMonth + 1}</li>`;
    }

    currentDate.innerText = `${months[currMonth]} ${currYear}`;
    daysTag.innerHTML = liTag;
}

renderCalendar();

prevNextButton.forEach((button) => {
    button.addEventListener("click", () => {
        // if clicked button is previus button then decrement current month by 1 else increment it by 1
        currMonth = button.id === "prev" ? currMonth - 1 : currMonth + 1;

        // if current month is less than 0 or greater than 11
        if (currMonth < 0 || currMonth > 11) {
            // creating a new date of current year & month and pass it as date value
            date = new Date(currYear, currMonth);
            // updating current year with new date year
            currYear = date.getFullYear();
            // updating current Month with new date month 
            currMonth = date.getMonth();
        } else {
            // else pass new Date as date value
            date = new Date();
        }

        renderCalendar();
    })
})

/*############### FORM SETUP ###############*/
const formHeader1 = document.getElementById("dropdow-header-1");
const formHeader2 = document.getElementById("dropdow-header-2");
const formOption1 = document.querySelector(".form__dropdow-options-1");
const formOption2 = document.querySelector(".form__dropdow-options-2");
const formItem1 = document.querySelectorAll(".form__dropdow-item-1")
const formItem2 = document.querySelectorAll(".form__dropdow-item-2")
const fromTitle1 = document.querySelector(".dropdow-title-1")
const fromTitle2 = document.querySelector(".dropdow-title-2")

if (formHeader1) {
    formHeader1.addEventListener("click", () => {
        formOption1.classList.toggle("show-dropdow")
    })

    formItem1.forEach((item) => {
        item.addEventListener("click", () => {
            console.log(item.textContent)

            const text = item.textContent;

            fromTitle1.innerText = text;

            formOption1.classList.remove("show-dropdow")
        })
    })
}

if (formHeader2) {
    formHeader2.addEventListener("click", () => {
        formOption2.classList.toggle("show-dropdow")
    })

    formItem2.forEach((item) => {
        item.addEventListener("click", () => {
            console.log(item.textContent)

            const text = item.textContent;

            fromTitle2.innerText = text;

            formOption2.classList.remove("show-dropdow")
        })
    })
}

/*############### SHOW FORM ###############*/
const contactModal = document.getElementById("contact-modal"),
    contactClose = document.getElementById("contact-close"),
    contactToggle = document.querySelectorAll("#contact-toggle"),
    modalToucher = document.querySelector(".modal-toutch"),
    formButton = document.querySelector(".form__button"),
    formMessage = document.querySelector(".form__message")


/*############### FORM VALIDATION ###############*/
const showFormError = (err) => {

    let formError = document.querySelector(".form__error")
    formError.innerHTML = err;
    formError.style.display = "flex";
    formError.style.color = "var(--red-color)";

    setTimeout(() => {
        formError.style.display = "none";
    }, 3000);
};

form.addEventListener("submit", async () => {

    event.preventDefault();

    let formName = document.querySelector(".form-name")
    let formEmail = document.querySelector(".form-email")
    let formNumber = document.querySelector(".form-number")
    let formSite = document.querySelector(".form-site")
    let formDrop1 = document.querySelector(".dropdow-text-1").textContent
    let formDrop2 = document.querySelector(".dropdow-text-2").textContent
    let formCheck = document.querySelector(".checkbox__input")
    let token = document.querySelector('input[name="cf-turnstile-response"]').value;
    let botao = document.querySelector(".form__button")
    const utm_source = document.getElementById("utm_source").value;
    const utm_id = document.getElementById("utm_id").value;
    const utm_medium = document.getElementById("utm_medium").value;
    const utm_term = document.getElementById("utm_term").value;

    const blockedEmails = /@(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com)$/i;

    console.log(formDrop1)

    if (formDrop1.trim() !== "Entre 0 a 1.000 saques" &&
        formDrop1.trim() !== "Entre 1.000 a 10.000 saques" &&
        formDrop1.trim() !== "Entre 10.000 a 100.000 saques" &&
        formDrop1.trim() !== "Mais de 100.000 saques") {
        showFormError("Por favor, selecione uma opção válida");
    }
    else if (blockedEmails.test(formEmail.value)) {
        showFormError("Aceitamos apenas emails comerciais");
    } else if(formDrop2.trim() !== "Preciso de KYC e faço manualmente" &&
        formDrop2.trim() !== "Preciso de KYC e não faço (nem manualmente)" &&
        formDrop2.trim() !== "Não preciso de KYC"
    ) {
        showFormError("Por favor, selecione uma opção válida");
    } else {

        try {

            const formData = new FormData();
            formData.append('name', formName.value);
            formData.append('email', formEmail.value);
            formData.append('phone', formNumber.value);
            formData.append('site', formSite.value);
            formData.append('saques', formDrop1.trim());
            formData.append('cenario', formDrop2.trim());
            formData.append('utm_source', utm_source);
            formData.append('utm_id', utm_id);
            formData.append('utm_medium', utm_medium);
            formData.append('utm_term', utm_term);
            formData.append("cf-turnstile-response", token)
        
            const response = await fetch('https://form.legitimuz.workers.dev/', {
                method: 'POST',
                body: formData
            });
        
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        
            const responseData = await response.json();
            formMessage.style.display = "block";
            var text = "Seus dados foram enviado com sucesso! Entraremos em contato o mais rápido possível.";
            botao.style.display = "none";
            formMessage.textContent = text;
        } catch (error) {
            formMessage.style.display = "block";
            formMessage.style.color = "red";
            var text = `Formulário não enviado! ${error}`;
            formMessage.textContent = text;
        }
    }
})

if (modalToucher) {
    modalToucher.addEventListener("click", () => {
        contactModal.classList.remove("show-modal");
    })
}

contactToggle.forEach((item) => {
    item.addEventListener("click", () => {
        contactModal.classList.add("show-modal");
    })
})

if (contactClose) {
    contactClose.addEventListener("click", () => {
        contactModal.classList.remove("show-modal");
    })
}
